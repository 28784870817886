/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import * as XLSX from "xlsx";
import _ from "lodash";
import "date-fns";
import axios from "axios";
import moment from "moment";

//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";
import { CloudUpload } from "@material-ui/icons";
import ErrorIcon from "@material-ui/icons/Error";
import SuccessIcon from "@material-ui/icons/Check";
import { Validator } from "jsonschema";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig, useUserState } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import useStyles from "./styles";
import { findMarketplace } from "../../healpers/utilityFunctions";

import SEOUpdateUploadTemplate from "../../healpers/assets/SEOUpdateUploadTemplate.xlsx";

const SKIP_FIELDS = {
  // Very deeply nested fields which cause issue when simplifying and rendering
  // purchasable_offer: true,
  cable: true
};
let marketplaceId = ""
function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    const srcObj = srcValue[0];
    if (typeof srcObj != "object") {
      return _.union(objValue, srcValue);
    }
    let srcKey = Object.keys(srcObj)[0];
    for (const index in objValue) {
      if (
        typeof objValue[index] == "object" &&
        !Array.isArray(objValue[index])
      ) {
        if (Object.keys(objValue[index]).indexOf(srcKey) >= 0) {
          continue;
        } else {
          objValue[index] = { ...objValue[index], ...srcObj };
          return objValue;
        }
      }
    }
    return _.union(objValue, srcValue);
  }
}

function deFllatenSchema(nestedKey, value) {
  const keys = nestedKey.split("$");
  let lastKey = keys.slice(keys.length - 1)[0];
  let obj = {};
  if (lastKey.includes("[]")) {
    lastKey = lastKey.replace(/\[\]\d/g, "");
    obj[lastKey] = [value];
  } else {
    obj[lastKey] = value;
  }

  let recentKey = lastKey;
  for (let key of keys.slice(0, keys.length - 1).reverse()) {
    if (key.includes("[]")) {
      key = key.replace(/\[\]\d/g, "");
      obj[key] = [{ ...obj }];
    } else {
      obj[key] = { ...obj };
    }
    if (recentKey) {
      delete obj[recentKey];
    }
    recentKey = key;
  }

  return obj;
}

function convertItem(type, value) {
  switch (type) {
    case "string":
      return value;
    case "integer":
      return parseInt(value);
    case "number":
      return parseFloat(value);
    case "boolean":
      return value.toLowerCase() == "true" ? true : false;
    default:
      console.log(type, value);
      return value;
  }
}

// Converts the simplified data back to payload
function processData(data, schema) {
  const STATIC_VALUES = {
    marketplace_id: marketplaceId,
    language_tag: "en_US",
  };
  if (typeof data == "undefined" || typeof schema == "undefined") {
    return undefined;
  }
  if (["string", "number", "boolean"].indexOf(typeof data) >= 0) {
    if (schema.type == "array") {
      return [processData(data, schema.items)];
    } else if (schema.type == "object") {
      const value = {};
      for (const key of Object.keys(schema.properties)) {
        const dataType = schema.properties[key].type;
        if (STATIC_VALUES[key]) {
          value[key] = STATIC_VALUES[key];
        } else if (typeof data != "undefined") {
          if (
            ["string", "boolean", "number", "integer"].indexOf(
              schema.properties[key].type
            ) >= 0
          ) {
            value[key] = convertItem(dataType, data);
          } else {
            value[key] = processData(data, schema.properties[key]);
          }
        }
      }
      return value;
    }
    return convertItem(schema.type, data);
  } else if (Array.isArray(data)) {
    const value = [];
    if (schema.type == "array") {
      for (const item of data) {
        value.push(processData(item, schema.items));
      }
    } else {
      throw new Error("Schema type is not array");
    }
    return value;
  } else if (typeof data == "object") {
    if (schema.type == "array") {
      return [processData(data, schema.items)];
    } else if (schema.type == "object") {
      const value = {};

      const isSimilarSchema = Object.keys(data).reduce((bool, key) => {
        return bool ? bool : Object.keys(schema.properties).indexOf(key) >= 0;
      }, false);
      if (!isSimilarSchema && Object.keys(data).length > 0) {
        console.log(
          "Schema not same. case not handled",
          data,
          schema.properties
        );
        throw new Error("Schema not same. case not handled");
      }
      for (const key of Object.keys(schema.properties)) {
        if (STATIC_VALUES[key]) {
          value[key] = STATIC_VALUES[key];
        } else if (typeof data[key] != "undefined") {
          if (
            ["string", "boolean", "number", "integer"].indexOf(
              schema.properties[key].type
            ) >= 0
          ) {
            value[key] = convertItem(schema.properties[key].type, data[key]);
          } else {
            value[key] = processData(data[key], schema.properties[key]);
          }
        }
      }
      return value;
    }
  } else throw new Error("Unseen type.Case not handled");
}

function validatePayload(data, schema) {
  const payload = _.cloneDeep(data);

  const v = new Validator();
  const obj = v.validate(payload, schema);

  return obj;
}

function makePayload(formData, productSchema, originalData) {
  const { required = {}, optional = {}, extra = {} } = formData;

  const payload = [required, optional, extra].reduce((obj, fields) => {
    // Convert all the fields back to their original values
    const data = Object.keys(fields).reduce((data, key) => {
      data[key] = processData(fields[key], productSchema.properties[key]);
      return data;
    }, {});
    return { ...obj, ...data };
  }, {});

  for (const key in SKIP_FIELDS) {
    if (originalData.attributes[key]) {
      payload[key] = originalData.attributes[key];
    }
  }

  return payload;
}

const CustomErrorsMessages = (props) => {
  return (
    <Box
      style={{
        color: "#4A4A4A",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h6">Custom Errors</Typography>
      <List>
        {props.errors.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ErrorIcon style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ color: "red" }} variant="body2">
                {item}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const CustomSuccessMessages = (props) => {
  return (
    <Box
      style={{
        color: "#4A4A4A",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h6">Success</Typography>
      <List>
        {props.success.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <SuccessIcon style={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ color: "green" }} variant="body2">
                {item}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default function CatalogItems(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();
  const userState = useUserState();
  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [mainTableDataSelectedRows, setMainTableDataSelectedRows] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [headerNames, setHeaderNames] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [seoUploadInProgress, setSeoUploadInProgress] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setfullPageLoader(true);
        const response = await axios.get(
          BASE_URL + api.catalogGetAPI,
          tokenConfig({
            vendorCode: vendorState.selected,
          })
        );

        if (response?.data?.data) {
          const data = response.data.data;
          const filteredKeys = [
            "id",
            "created_at",
            "updated_at",
            "vendorCode",
            "supported",
          ];
          const headerNames = Object.keys(data[0])
            .filter((key) => filteredKeys.indexOf(key) < 0)
            .map((key) => {
              return {
                headerName: key
                  .replaceAll(/[A-Z]/g, (x) => ` ${x}`)
                  .replace(/^\w/g, (x) => x.toUpperCase()),
                field: key,
              };
            });
          setHeaderNames(headerNames);
          setmainTableData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState.selected) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
      setMainTableDataSelectedRows([]);
    };
  }, [vendorState.selected]);

  async function uploadCSV(file) {
    const reader = new FileReader();
    setErrorMessage([]);
    setSuccessMessage([]);
    marketplaceId = findMarketplace(vendorState?.selected);

    reader.onload = async (evt) => {
      setfullPageLoader(true);
      const errorWorkBook = XLSX.utils.book_new();
      try {
        // seterr(null)

        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { raw: false });

        const errors = [];
        const success = [];
        let itemIndex = 0;
        for (const entry of data) {
          itemIndex++;
          let dataObj = {};
          for (const [key, value] of Object.entries(entry)) {
            if (value == "") {
              continue;
            }
            const obj = deFllatenSchema(key, value);
            dataObj = _.mergeWith(dataObj, obj);
          }
          let productSchema = null;
          let originalData = null;
          const { asin, sku, marketplaceId, productType } = dataObj.root;
          const productInfo = `${itemIndex}-${asin}`;
          try {
            const response = await axios.get(
              BASE_URL + api.spListing + vendorState.selected,
              tokenConfig({
                asin,
                sku,
                marketplaceId,
              })
            );
            productSchema = response.data.data.productSchema.schema;
            originalData = response.data.data.payload;
          } catch (err) {
            console.log(err);
            const { response } = err;
            if (response && response.data && response.data.message) {
              const { message } = response.data;
              errors.push(productInfo + message);
            } else {
              errors.push(productInfo + "Error occurred while loading data");
            }
            continue;
          }
          const attributes = makePayload(
            {
              required: dataObj.root.required,
              optional: dataObj.root.optional,
              extra: dataObj.root.extra,
            },
            productSchema,
            originalData
          );

          const validationObj = validatePayload(attributes, productSchema);

          if (validationObj.errors.length != 0) {
            console.log("validation obj", validationObj, attributes);
            const header = "Message";
            const messages = [];
            for (const { message, property } of validationObj.errors) {
              messages.push(message + property);
            }
            messages.sort();
            const worksheet = XLSX.utils.json_to_sheet(
              messages.map((m) => ({ message: m }))
            );
            XLSX.utils.book_append_sheet(
              errorWorkBook,
              worksheet,
              productInfo.substring(0, 30)
            );
            errors.push(
              `${productInfo} validation failed. Check file for errors`
            );
            continue;
          }

          try {
            const payload = {
              productType,
              requirements: "LISTING",
              attributes,
            };
            await axios.post(
              BASE_URL + api.spListing + vendorState.selected,
              payload,
              tokenConfig({
                asin,
                sku,
                marketplaceId,
              })
            );
            success.push(`${productInfo} update successful.`);
          } catch (err) {
            const { response } = err;
            if (response?.data?.errors) {
              for (const error of response?.data?.errors) {
                errors.push(`${productInfo} update failed. ${error}`);
              }
              errors.push(
                `${productInfo} update failed. ${response?.data?.message}. Check with admin if problem persists`
              );
            } else if (response?.data?.message) {
              errors.push(
                `${productInfo} update failed. ${response?.data?.message}. Check with admin if problem persists`
              );
            } else {
              errors.push(
                `${productInfo} update failed. ${err?.message}. Check with admin`
              );
            }
          }
        }
        setErrorMessage(errors);
        setSuccessMessage(success);
        setfullPageLoader(false);
      } catch (err) {
        console.log("Error", err);
        const errorBlog = new Blob(
          [JSON.stringify(err, Object.getOwnPropertyNames(err), 2)],
          {
            type: "application/json",
          }
        );
        var downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.download = "ERROR_LOG" + ".json";
        downloadAnchorNode.href = window.URL.createObjectURL(errorBlog);
        downloadAnchorNode.click();
        setErrorMessage(["Unknwon error has occurred. Contact the admin"]);
      } finally {
        setfullPageLoader(false);
        const sheetNames = errorWorkBook.SheetNames;
        if (sheetNames.length > 0) {
          const excelBuffer = XLSX.write(errorWorkBook, {
            bookType: "xlsx",
            type: "array",
          });
          const excelBlob = new Blob([excelBuffer], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          var excelFileDownloadEle = document.createElement("a");
          excelFileDownloadEle.download = "BULK UPLOAD ERRORS" + ".xlsx";
          excelFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
          excelFileDownloadEle.click();
        }
      }
    };
    reader.readAsArrayBuffer(file);
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Catalog Items"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Catalog
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                All Catalog Items
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {errorMessage.length > 0 && (
              <CustomErrorsMessages errors={errorMessage} />
            )}
            {successMessage.length > 0 && (
              <CustomSuccessMessages success={successMessage} />
            )}
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      gap: "8px",
                      alignItems: "start",
                    }}
                  >
                    <div style={{
                      display: "flex",
                      gap: "8px",
                    }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PrintIcon />}
                        onClick={() => exportFunctionRef.current.onBtnExport()}
                      >
                        Export
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PrintIcon />}
                        disabled={(mainTableData || []).length === 0}
                        onClick={() => {
                          const catalogItems = mainTableData?.map((item) => {
                            return {
                              "Vendor Code": vendorState?.selected || "",
                              ASIN: item.asin || "",
                              SKU: item.sku || "",
                              Title: item.itemName || "",
                              Status: item.status || "",
                              Price: item.price || "",
                              "Product Type": item.productType || "",
                              Category: item.category || "",
                              "Sub Category": item.subCategory || "",
                              "Updated At": item.updatedAt || "",
                            }
                          });
                          const worksheet = XLSX.utils.json_to_sheet(catalogItems);
                          const workbook = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(workbook, worksheet, "Catalog");

                          XLSX.writeFile(workbook, `Catalog Items-${vendorState?.selected}-${moment().format("MM-DD-YYYY")}.xlsx`);
                        }}
                      >
                        Export Catalog
                      </Button>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                      disabled={(mainTableDataSelectedRows || []).length === 0}
                      onClick={() => {
                        const catalogItems = mainTableDataSelectedRows?.map((item) => {
                          return {
                            ASIN: item.asin || "",
                            SKU: item.sku || "",
                            Title: item.itemName || "",
                            BP1: item.bulletPoints?.[0] || "",
                            BP2: item.bulletPoints?.[1] || "",
                            BP3: item.bulletPoints?.[2] || "",
                            BP4: item.bulletPoints?.[3] || "",
                            BP5: item.bulletPoints?.[4] || "",
                            Description: item.itemDescription || "",
                            "PT": item.productType || "",
                            "Vendor Code": vendorState?.selected || "",
                            "VC Vendor Code": item.vcVendorCode || "",
                          }
                        });
                        const worksheet = XLSX.utils.json_to_sheet(catalogItems);
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                        XLSX.writeFile(workbook, `SEO Export-${vendorState?.selected}-${moment().format("MM-DD-YYYY")}.xlsx`);
                      }}
                    >
                      SEO Export
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                      gap: "8px",
                      alignItems: "end",
                    }}
                  >
                    <label htmlFor="upload-csv">
                      <input
                        style={{ display: "none" }}
                        id="upload-csv"
                        name="upload-csv"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={(e) => uploadCSV(e.target.files[0])}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        component="span"
                        startIcon={<CloudUpload />}
                      >
                        Catalog Upload
                      </Button>
                    </label>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "space-between"
                      }}
                    >
                      <a
                        href={SEOUpdateUploadTemplate}
                        download="SEOUpdateUploadTemplate.xlsx"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<PrintIcon />}
                        >
                          SEO Template
                        </Button>
                      </a>
                      <label htmlFor="upload-seo">
                        <input
                          style={{ display: "none" }}
                          id="upload-seo"
                          name="upload-seo"
                          type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={async (e) => {
                            const reader = new FileReader();
                            reader.onload = async (event) => {
                              try {
                                setSeoUploadInProgress(true);
                                const workbook = XLSX.read(event.target.result, {
                                  type: "array",
                                });
                                const worksheetName = workbook.SheetNames[0];
                                const worksheet = workbook.Sheets[worksheetName];
                                const data = XLSX.utils.sheet_to_json(worksheet);
                                if (data.length > 0) {
                                  let isFileValid = true;
                                  for (let index = 0; index < data.length; index++) {
                                    if (
                                      !data[index]["ASIN"] ||
                                      !data[index]["SKU"] ||
                                      !data[index]["Title"] ||
                                      !data[index]["BP1"] ||
                                      !data[index]["PT"] ||
                                      !data[index]["Vendor Code"] ||
                                      (data[index]["Vendor Code"].endsWith("VC") && !data[index]["VC Vendor Code"])
                                    ) {
                                      window.alert(
                                        `Missing required field on row ${
                                          index + 1
                                        }. Check the file and Try again.`
                                      );
                                      isFileValid = false;
                                      break;
                                    }
                                  }
                                  if (isFileValid) {
                                    try {
                                      const response = await axios({
                                        method: "post",
                                        url: BASE_URL + api.uploadSeoUpdate,
                                        data: {
                                          seoUpdate: data,
                                          uploader: userState.userData?.email,
                                        },
                                      });
                                      if (response.data?.status === "Success") {
                                        window.alert(response.data?.message + "\nCheck the output file for more details.");
                                        const wb = XLSX.utils.book_new();
                                        const ws = XLSX.utils.json_to_sheet(response.data?.data || []);
                                        XLSX.utils.book_append_sheet(wb, ws, "Output");
                                        XLSX.writeFile(wb, `seo-upload-result-${moment().format("DD-MM-YYYY")}.xlsx`);
                                      } else {
                                        window.alert(
                                          response.data?.message || "Failed to upload seo update."
                                        );
                                      }
                                    } catch (e) {
                                      console.log(e);
                                      window.alert("Failed to upload seo update.");
                                    }
                                  }
                                } else {
                                  window.alert("File is empty. Try again.");
                                }
                              } catch (err) {
                                console.error(err);
                                window.alert(
                                  err?.message || "File validation failed."
                                );
                              } finally {
                                setSeoUploadInProgress(false);
                              }
                            };
                            reader.readAsArrayBuffer(e.target.files[0]);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component="span"
                          startIcon={<CloudUpload />}
                          disabled={seoUploadInProgress}
                        >
                          SEO Upload
                          <CircularProgress
                            color="inherit"
                            style={{
                              display: seoUploadInProgress ? "block" : "none",
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                            }}
                          />
                        </Button>
                      </label>
                    </div>
                  </div>
                </>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                setTableSelectedData={(tableSelectedData) => setMainTableDataSelectedRows(tableSelectedData)}
                headerNames={headerNames}
                classes={classes}
              />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
