let serverURL = 'https://bidashboard.salesduo.com';
console.log("process.env", process.env);
if(process.env.REACT_APP_ENV === 'stage') {
  serverURL = 'http://54.172.254.64';
} else if(process.env.REACT_APP_ENV === 'dev_server') {
  serverURL = 'http://54.147.175.250:8080';
} else if(process.env.REACT_APP_ENV === 'brand_finder') {
  serverURL = 'http://54.147.175.250:8081';
} else if(process.env.NODE_ENV === 'development') {
  // serverURL = 'http://54.147.175.250:8080';
  // serverURL = 'http://localhost:8080';
  serverURL = 'http://54.172.254.64:8090';
}

export const BASE_URL = serverURL;
